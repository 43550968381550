.customNodeContainer {
    padding: 16px;
    border-radius: 8px;
    background-color: #242424;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: max-content;
}

.nodeUserImage {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
}

.userDetails {
    width: max-content;
    height: max-content;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
}

.nodeUsername {
    color: rgba(242, 242, 247, 0.90);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.28px;
    word-wrap: break-word;
    margin: 0;
    /* Remove default margin */
}

.nodeShortCodeId {
    color: rgba(199, 199, 204, 0.90);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.28px;
    word-wrap: break-word;
    margin: 0;
    /* Remove default margin */
}