.App {
  text-align: center;
}

body{
  /* overflow-y: hidden; */
  overflow-x: hidden;
  background-color: #F3F4F8;
}
.poppins-font {
  font-family: 'Poppins', sans-serif!important;
}